import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation} from 'react-router-dom'
import './index.css'
import { Layout, Menu, Image, Flex } from 'antd';
import { Dashboard } from '../pages/Dashboard'
import { Notifications, UserProfile } from '../components/Header'
import { Account, Agent, History, Players, Reports, RequestWithdrawal, Transaction } from '../pages';
const { Header, Sider, Content } = Layout

const RouteF= ()=>{
    let navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([])
  const [currentTab, setCurrentTab]= useState('1')


  function getItem(label, key, icon, onClick, children, type) {
    return { key, icon, onClick, children, label, type }
  }


  useEffect(()=>{
    let tab= location?.pathname?.split("/")[1]
    tab= tab==='' ? '1':
        tab==='agent' ? '2':
        tab==='player' ? '3':
        tab==='withdrawalrequest' ? '4':
        tab==='history' ? '5':
        tab==='transaction' ? '6':
        tab==='report' ? '7':
        tab==='account' ? '8':
        '1'
    setCurrentTab(tab)
  }, [location])

  useEffect(() => {
    let itemsArr = []
        itemsArr.push(getItem('Dashboard', '1', 
          <img src="/assets/icons/dashboard.png" width={'20px'} preview={false} alt="" />, 
          () => { navigate("/", { replace: true }) }))

        itemsArr.push(getItem('Agents', '2', 
          <img src="/assets/icons/agent-1.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/agent", { replace: true }) }))

        itemsArr.push(getItem('Players', '3', 
          <img src="/assets/icons/games.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/player", { replace: true }) }))
        
        itemsArr.push(getItem('Withdrawal Request', '4', 
          <img src="/assets/icons/withdrawal.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/withdrawalrequest", { replace: true }) }))

        itemsArr.push(getItem('History', '5', 
          <img src="/assets/icons/history.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/history", { replace: true }) }))

        itemsArr.push(getItem('Transaction', '6', 
          <img src="/assets/icons/invest.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/transaction", { replace: true }) }))

        itemsArr.push(getItem('Reports', '7', 
          <img src="/assets/icons/report.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/report", { replace: true }) }))

        itemsArr.push(getItem('Account', '8', 
          <img src="/assets/icons/account.png" width={'18px'} preview={false} alt="" /> , 
          () => { navigate("/account", { replace: true }) }))
      setItems([...itemsArr])
    }, [navigate])

    return (
        <Layout style={{ height: '100vh'}}>
            <Sider trigger={null} className='card-bg' collapsible collapsed={collapsed} style={{ height: '100vh', overflowY: 'auto' }}>
                <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
                <Image
                    style={{width: collapsed?"100%":'100px'}}
                    height={'auto'}
                    src="./assets/logo.png"
                    alt='Casino 617 Admin Panel'
                    
                    preview= {false}
                />
                </div>
                <Menu
                  defaultSelectedKeys={['1']}
                  selectedKeys={[currentTab]}
                  mode="inline"
                  theme="dark"
                // inlineCollapsed={collapsed}
                  items={items}
                className='listitem card-bg activeclass'
                />
            </Sider>
            <Layout className="site-layout">
                <Header
                  className="site-layout-background card-bg"
                  style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center'
                  }}
                  >
                  <div style={{ width: '95%', display: 'flex', justifyContent: 'space-between' }}>
                      
                      <div className= 'trigger border-0-btn' onClick={() => setCollapsed(!collapsed)}>
                          {
                              collapsed ? 
                              <Image src='/assets/icons/collaps-r.png' width={'35.46px'} preview={false} />
                              : 
                              <Image src='/assets/icons/collaps-l.png' width={'35.46px'} preview={false} />
                          }
                      </div>
                      <Flex align='center' gap={15}>
                        <Notifications />
                        <UserProfile />
                      </Flex>
                  </div>
                </Header>

                <Content
                className=" scroll-bar bg-main"
                style={{
                    padding: '34px',
                    minHeight: 280,
                    overflowY: 'auto'
                }}
                >
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/player" element={<Players />} />
                      <Route path="/agent" element={<Agent />} />
                      <Route path="/withdrawalrequest" element={<RequestWithdrawal />} />
                      <Route path="/history" element={<History />} />
                      <Route path="/transaction" element={<Transaction />} />
                      <Route path="/report" element={<Reports />} />
                      <Route path="/account" element={<Account />} />
                    </Routes>
                </Content>
            </Layout>
        </Layout>
    )
}

export default RouteF