import React from 'react'
import { Button, Card, Col, Dropdown, Flex, Form, Row, Table, Typography } from 'antd';
import { ModuleTopHeading } from '../../PageComponents';
import { FilterOutlined } from '@ant-design/icons';
import { MyInputnoLabel } from '../../Forms';

const { Text } = Typography

const CommissionReport = () => {

    const [form] = Form.useForm();

    const items = [
        {
            label: <a href="#" className='text-white'>A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#" className='text-white'>Z-A</a>,
            key: '1',
        },
    ];

    const columns = [
        {
            title: <Text className='text-white'>Commission From</Text>,
            dataIndex: 'commissionFrom',
        },
        {
            title: <Text className='text-white'>Commission Level</Text>,
            dataIndex: 'commissionLevel',
        },
        {
            title: <Text className='text-white'>Amount</Text>,
            dataIndex: 'amount',
        },
        {
            title: <Text className='text-white'>Title</Text>,
            dataIndex: 'title',
        },
        {
            title: <Text className='text-white'>Transaction</Text>,
            dataIndex: 'transaction',
            width:150
        },
    ];

    const data = [
        {
            key: '1',
            commissionFrom: <Text className='text-white'>Abc</Text>,
            commissionLevel: <Text className='text-white'>Nil</Text>,
            amount: <Text className='text-white'>p 123,000</Text>,
            title: <Text className='text-white'>Xyz</Text>,
            transaction: <Text className='text-white'>p 123,000</Text>,
        },
        {
            key: '2',
            commissionFrom: <Text className='text-white'>Abc</Text>,
            commissionLevel: <Text className='text-white'>Nil</Text>,
            amount: <Text className='text-white'>p 123,000</Text>,
            title: <Text className='text-white'>Xyz</Text>,
            transaction: <Text className='text-white'>p 123,000</Text>,
        },
        {
            key: '3',
            commissionFrom: <Text className='text-white'>Abc</Text>,
            commissionLevel: <Text className='text-white'>Nil</Text>,
            amount: <Text className='text-white'>p 123,000</Text>,
            title: <Text className='text-white'>Xyz</Text>,
            transaction: <Text className='text-white'>p 123,000</Text>,
        },
        {
            key: '4',
            commissionFrom: <Text className='text-white'>Abc</Text>,
            commissionLevel: <Text className='text-white'>Nil</Text>,
            amount: <Text className='text-white'>p 123,000</Text>,
            title: <Text className='text-white'>Xyz</Text>,
            transaction: <Text className='text-white'>p 123,000</Text>,
        },
        {
            key: '5',
            commissionFrom: <Text className='text-white'>Abc</Text>,
            commissionLevel: <Text className='text-white'>Nil</Text>,
            amount: <Text className='text-white'>p 123,000</Text>,
            title: <Text className='text-white'>Xyz</Text>,
            transaction: <Text className='text-white'>p 123,000</Text>,
        },
      ];

  return (
    <div>
        <Card className='card-bg rounded border0'>
            <Row gutter={[24,24]}>
                <Col xs={24} sm={24} md={12} lg={18}>
                    <ModuleTopHeading
                        name='Commission report'
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex justify='end' gap={10}>
                                <MyInputnoLabel
                                    name='name'
                                    label='Search'
                                    placeholder='Search here ...'
                                    value={form.getFieldValue("name") || ''} 
                                    className='w-100'
                                    onChange={(e)=>e.target.value}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    mode
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                        className='bg-main border0 text-white btn-filter'
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                <Col span={24}>
                    <Table
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {CommissionReport}