import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Card, Table, Flex, Space, Dropdown } from 'antd'
import { ActionButton, ModuleTopHeading, MyInputnoLabel } from '../../components';
import { CheckCircleOutlined, CloseCircleOutlined, FilterOutlined } from '@ant-design/icons';

const { Text } = Typography

const RequestWithdrawal = () => {

    const [form] = Form.useForm();
  
    const items = [
        {
            label: <a href="#" className='text-white'>A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#" className='text-white'>Z-A</a>,
            key: '1',
        },
    ];

    const columns = [
        {
            title: <Text className='text-white'>Player ID</Text>,
            dataIndex: 'playerId',
            width: 250
        },
        {
            title: <Text className='text-white'>Player name</Text>,
            dataIndex: 'playerName',
            width: 250
        },
        {
            title: <Text className='text-white'>Amount</Text>,
            dataIndex: 'amount',
            width: 250
        },
        {
            title: <Text className='text-white'>Time stamp</Text>,
            dataIndex: 'timeStamp',
            width: 250
        },
        {
            title: <Text className='text-white'>Action</Text>,
            key: 'action',
            width: 130,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Accept'
                        shape="circle"
                        icon={<CheckCircleOutlined style={{fontSize:18}} className='text-white' />}
                        onClick={() => {}}
                        type='ghost'
                    />
                    <ActionButton
                        title='Deny'
                        shape="circle"
                        icon={<CloseCircleOutlined style={{fontSize:18}} className='text-white' />}
                        onClick={() => {}}
                        type='ghost'
                    />
                </Space>
            ),
        },
    ];
  
      const data = [
          {
              key: '1',
              playerId: <Text className='text-white'>01</Text>,
              playerName: <Text className='text-white'>Ali</Text>,
              amount: <Text className='text-white'>1200$</Text>,
              timeStamp: <Text className='text-white'>12/12/2023 12:00 AM</Text>,
          },
          {
              key: '2',
              playerId: <Text className='text-white'>02</Text>,
              playerName: <Text className='text-white'>Sheraz</Text>,
              amount: <Text className='text-white'>1000$</Text>,
              timeStamp: <Text className='text-white'>12/10/2023 10:00 AM</Text>,
          },
          {
              key: '3',
              playerId: <Text className='text-white'>03</Text>,
              playerName: <Text className='text-white'>Shujat</Text>,
              amount: <Text className='text-white'>2000$</Text>,
              timeStamp: <Text className='text-white'>18/06/2023 12:00 AM</Text>,
          },
          {
              key: '4',
              playerId: <Text className='text-white'>04</Text>,
              playerName: <Text className='text-white'>Haris</Text>,
              amount: <Text className='text-white'>2400$</Text>,
              timeStamp: <Text className='text-white'>12/12/2023 12:00 AM</Text>,
          },
          {
              key: '5',
              playerId: <Text className='text-white'>05</Text>,
              playerName: <Text className='text-white'>Aqib</Text>,
              amount: <Text className='text-white'>2100$</Text>,
              timeStamp: <Text className='text-white'>12/12/2023 12:00 AM</Text>,
          },
        ];
  
    return (
        <div>
            <Card className='card-bg rounded border0'>
                <Row gutter={[24,24]}>
                    <Col xs={24} sm={24} md={12} lg={18}>
                        <ModuleTopHeading
                            name='Withdrawal request'
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex justify='end' gap={10}>
                                <MyInputnoLabel
                                    name='name'
                                    label='Search'
                                    placeholder='Search here ...'
                                    value={form.getFieldValue("name") || ''} 
                                    className='w-100'
                                    onChange={(e)=>e.target.value}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    mode
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                        className='bg-main border0 text-white btn-filter'
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export {RequestWithdrawal}