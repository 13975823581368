import React from 'react'
import { Button, Card, Col, Dropdown, Flex, Form, Row, Table, Typography } from 'antd';
import { ModuleTopHeading } from '../../PageComponents';
import { FilterOutlined } from '@ant-design/icons';
import { MyInputnoLabel } from '../../Forms';

const { Text } = Typography

const BalanceReport = () => {

    const [form] = Form.useForm();

    const items = [
        {
            label: <a href="#" className='text-white'>A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#" className='text-white'>Z-A</a>,
            key: '1',
        },
    ];

    const columns = [
        {
            title: <Text className='text-white'>Game Name</Text>,
            dataIndex: 'gameName',
        },
        {
            title: <Text className='text-white'>You Select</Text>,
            dataIndex: 'ySelect',
        },
        {
            title: <Text className='text-white'>Result</Text>,
            dataIndex: 'result',
        },
        {
            title: <Text className='text-white'>Invest</Text>,
            dataIndex: 'invest',
        },
        {
            title: <Text className='text-white'>Win or Loss</Text>,
            dataIndex: 'status',
            width:150
        },
    ];

    const data = [
        {
            key: '1',
            gameName: <Text className='text-white'>Abc</Text>,
            ySelect: <Text className='text-white'>Nil</Text>,
            result: <Text className='text-white'>Nil</Text>,
            invest: <Text className='text-white'>p 123,000</Text>,
            status: <Text className='text-white'>Won</Text>,
        },
        {
            key: '2',
            gameName: <Text className='text-white'>Abc</Text>,
            ySelect: <Text className='text-white'>Nil</Text>,
            result: <Text className='text-white'>Nil</Text>,
            invest: <Text className='text-white'>p 123,000</Text>,
            status: <Text className='text-white'>Lost</Text>,
        },
        {
            key: '3',
            gameName: <Text className='text-white'>Abc</Text>,
            ySelect: <Text className='text-white'>Nil</Text>,
            result: <Text className='text-white'>Nil</Text>,
            invest: <Text className='text-white'>p 123,000</Text>,
            status: <Text className='text-white'>Won</Text>,
        },
        {
            key: '4',
            gameName: <Text className='text-white'>Abc</Text>,
            ySelect: <Text className='text-white'>Nil</Text>,
            result: <Text className='text-white'>Nil</Text>,
            invest: <Text className='text-white'>p 123,000</Text>,
            status: <Text className='text-white'>Won</Text>,
        },
        {
            key: '5',
            gameName: <Text className='text-white'>Abc</Text>,
            ySelect: <Text className='text-white'>Nil</Text>,
            result: <Text className='text-white'>Nil</Text>,
            invest: <Text className='text-white'>p 123,000</Text>,
            status: <Text className='text-white'>Lost</Text>,
        },
      ];

  return (
    <div>
        <Card className='card-bg rounded border0'>
            <Row gutter={[24,24]}>
                <Col xs={24} sm={24} md={12} lg={18}>
                    <ModuleTopHeading
                        name='Balance report'
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Flex justify='end' gap={10}>
                            <MyInputnoLabel
                                name='name'
                                label='Search'
                                placeholder='Search here ...'
                                value={form.getFieldValue("name") || ''} 
                                className='w-100'
                                onChange={(e)=>e.target.value}
                            />
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                mode
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                            >
                                <Button
                                    icon={<FilterOutlined />}
                                    className='bg-main border0 text-white btn-filter'
                                >
                                    Filter
                                </Button>
                            </Dropdown>
                        </Flex>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {BalanceReport}