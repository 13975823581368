import React from 'react'
import { Card, Col, Flex, Row } from 'antd'
import { BalanceReport, CommissionReport, ModuleTopHeading, Transaction } from '../../components'

const Reports = () => {
  return (
    <div>
        <Card className='card-bg rounded border0'>
          <Row gutter={[24,24]}>
              <Col xs={24} sm={24} md={18}>
                  <ModuleTopHeading
                      name='Report'
                      // onClick={()=>setVisible(true)}
                  />
              </Col>
            </Row>
        </Card>
        {/* <Flex vertical gap={20}>
            <Transaction />
            <CommissionReport />
            <BalanceReport />
        </Flex> */}
    </div>
  )
}

export {Reports}