import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Card, Table, Flex, Dropdown } from 'antd'
import { ModuleTopHeading, MyInputnoLabel } from '../../components';
import { FilterOutlined } from '@ant-design/icons';


const { Text } = Typography


const Depositcomponent = () => {
  const [form] = Form.useForm();
  const [ visible, setVisible ]= useState(false);
  

  const items = [
    {
        label: <a href="#" className='text-white'>A-Z</a>,
        key: '0',
    },
    {
        label: <a href="#" className='text-white'>Z-A</a>,
        key: '1',
    },
];

    const columns = [
        {
            title: <Text className='text-white'>Bank name</Text>,
            dataIndex: 'bankName',
            width:250
        },
        {
            title: <Text className='text-white'>Amount</Text>,
            dataIndex: 'amount',
            width:250
        },
        {
            title: <Text className='text-white'>Account holder name</Text>,
            dataIndex: 'accountHoldername',
            width:250
        },
        {
            title: <Text className='text-white'>Time stamp</Text>,
            dataIndex: 'timeStamp',
            width:200
        }
    ];

    const data = [
        {
            key: '1',
            bankName: <Text className='text-white'>HBL</Text>,
            amount: <Text className='text-white'>6000$</Text>,
            accountHoldername: <Text className='text-white'>Ahmed</Text>,
            timeStamp: <Text className='text-white'>12/12/2023 12:00 AM</Text>,
        },
        {
            key: '2',
            bankName: <Text className='text-white'>Askare bank</Text>,
            amount: <Text className='text-white'>20,000$</Text>,
            accountHoldername: <Text className='text-white'>Ali</Text>,
            timeStamp: <Text className='text-white'>10/02/2023 09:00 AM</Text>,
        },
        {
            key: '3',
            bankName: <Text className='text-white'>Zubair</Text>,
            amount: <Text className='text-white'>17,000$</Text>,
            accountHoldername: <Text className='text-white'>Shahzad</Text>,
            timeStamp: <Text className='text-white'>17/03/2024 02:00 AM</Text>,
        },
      ];

  return (
    <div>
        <Card className='card-bg rounded border0'>
            <Row gutter={[24,24]}>
                <Col xs={24} sm={24} md={12} lg={18}>
                    <ModuleTopHeading
                        name='Deposit'
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Flex justify='end' gap={10}>
                            <MyInputnoLabel
                                name='name'
                                label='Search'
                                placeholder='Search here ...'
                                value={form.getFieldValue("name") || ''} 
                                className='w-100'
                                onChange={(e)=>e.target.value}
                            />
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                mode
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                            >
                                <Button
                                    icon={<FilterOutlined />}
                                    className='bg-main border0 text-white btn-filter'
                                >
                                    Filter
                                </Button>
                            </Dropdown>
                        </Flex>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {Depositcomponent}