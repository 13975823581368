import React, { useState } from 'react'
import { Button, Card, Col, Flex, Row } from 'antd'
import { Depositcomponent, ModuleTopHeading, Withdrawcomponent } from '../../components'

const History = () => {
    const [ current, setCurrent ] = useState(0)

    const data = {
        head: [
            { item: 'Deposit' },
            { item: 'Withdraw' },
        ],
        contenttab: [
            { innercontent: <Depositcomponent /> },
            { innercontent:  <Withdrawcomponent />},
        ]
    }

    return (
        <div>
            
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Card className='card-bg rounded border0'>
                            <ModuleTopHeading name='History' />
                            <Flex gap={'small'} className='scroll-mbl mt-3'>
                                {data?.head?.map((headtext, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => setCurrent(index)}
                                        className={current === index ? 'btnactive btnpill' : 'btnpill'}
                                    >
                                        {headtext?.item}
                                    </Button>
                                ))}
                            </Flex>
                        </Card>
                    </Col>
                    <Col span={24}>
                        {data?.contenttab[current]?.innercontent}
                    </Col>
                </Row>
        </div>
    )
}

export { History }
