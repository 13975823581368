import React,{ useState } from 'react'
import { Button, Row, Col, Typography, Card, Table, Flex, Tag, Space } from 'antd'
import { ModuleTopHeading, AddTicketDrawer, ActionButton, AlertModal } from '../../components';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';


const { Text } = Typography


const SupportTicket = () => {
    
  const [ visible, setVisible ]= useState(false);
  const [ visiblemodal, setVisibleModal ] = useState(false)
  const [ visibleonly, setVisibleOnly ] = useState('')
  
    const columns = [
        {
            title: <Text className='text-white'>Subject</Text>,
            dataIndex: 'subject',
        },
        {
            title: <Text className='text-white'>Status</Text>,
            dataIndex: 'status',
        },
        {
            title: <Text className='text-white'>Priority</Text>,
            dataIndex: 'priority',
        },
        {
            title: <Text className='text-white'>Last Reply</Text>,
            dataIndex: 'lastReply',
        },
        {
            title: <Text className='text-white'>Action</Text>,
            dataIndex: 'action',
            fixed: 'right',
            width: 100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View ticket'
                        icon={<EyeOutlined className='text-white' />}
                        onClick={() => { setVisible(true); setVisibleOnly(row?.status)}}
                        shape="circle"
                        ghost
                        className='borderwhite'

                    />
                    <ActionButton
                        title='Delete ticket'
                        icon={<DeleteOutlined className='text-white' />}
                        onClick={() => { setVisibleModal({name:row?.subject}) }}
                        shape="circle"
                        ghost
                        className='borderwhite'
                    />
                </Space>
            ),
        }
    ];

    const data = [
        {
            key: '1',
            subject: <Text className='text-white'>[Ticket#751583] leave</Text>,
            status: <Tag className='bagde-cs'>Open</Tag>,
            priority: <Text className='text-white'>High</Text>,
            lastReply: <Text className='text-white'>36 seconds ago</Text>,
        },
        {
            key: '2',
            subject: <Text className='text-white'>[Ticket#751583] leave</Text>,
            status: <Tag className='bagde-cs'>Open</Tag>,
            priority: <Text className='text-white'>High</Text>,
            lastReply: <Text className='text-white'>36 seconds ago</Text>,
        },
        {
            key: '3',
            subject: <Text className='text-white'>[Ticket#751583] leave</Text>,
            status: <Tag className='bagde-cs'>Open</Tag>,
            priority: <Text className='text-white'>High</Text>,
            lastReply: <Text className='text-white'>36 seconds ago</Text>,
        },
        {
            key: '4',
            subject: <Text className='text-white'>[Ticket#751583] leave</Text>,
            status: <Tag className='bagde-cs'>Open</Tag>,
            priority: <Text className='text-white'>High</Text>,
            lastReply: <Text className='text-white'>36 seconds ago</Text>,
        },
        {
            key: '5',
            subject: <Text className='text-white'>[Ticket#751583] leave</Text>,
            status: <Tag className='bagde-cs'>Open</Tag>,
            priority: <Text className='text-white'>High</Text>,
            lastReply: <Text className='text-white'>36 seconds ago</Text>,
        },
      ];

  return (
    <div>
        <Card className='card-bg rounded border0'>
            <Row gutter={[24,24]}>
                <Col span={16}>
                    <ModuleTopHeading
                        name='Support Ticket'
                        onClick={()=>setVisible(true)}
                    />
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
            <AddTicketDrawer
                visible={visible}
                visibleonly={visibleonly}
                onClose={()=> {setVisible(false);setVisibleOnly(null)}}
            />
            <AlertModal 
                visiblemodal={visiblemodal}
                onClose={()=>setVisibleModal(false)}
            />
        </Card>
    </div>
  )
}

export {SupportTicket}